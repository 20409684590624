<template>
    <dashboard-layout>
        <div class="content-area__body">
            <!-- job section -->
            <section class="job-section section-gap">
                <div class="section-title-wrap"><h2 class="section-title">{{ $t("Unfinished Jobs")}}</h2></div>
                <div class="row row-cols-md-4" v-if="isContentLoading">
                    <div class="col" v-for="index in 4" :key="index">
                        <CandidateJobLoader></CandidateJobLoader>
                    </div>
                </div>
                <template v-else>
                    <div v-if="isEmpty">
                        <h4 class="empty-message ">{{ $t("No unfinished jobs found.")}}</h4>
                    </div>
                    <div class="row row-cols-md-4" v-else>
                        <div class="col" v-for="job in unfinishedJobs">
                            <div class="job-card">
                                <div class="d-flex align-items-center justify-content-between">
                                    <div class="job-icon"><i class="eicon e-briefcase"></i></div>
                                    <button class="control-button control-button--danger has_bg_color" @click.prevent="deleteJobApply(job.application_id)">
                                        <div class="control-button__icon"><i class="eicon e-delete"></i></div>
                                        <span>{{$t('Discard')}}</span>
                                    </button>
                                </div>
                                <a :href="job.url" target="_blank" class="job-title" v-text="job.title"></a>
                                <ul>
                                    <li>{{job.companyName}}, {{job.location}}</li>
                                    <li>{{ $t("Deadline")}}: {{job.deadline}}</li>
                                    <li>{{ $t("No of Vacancies")}}: {{job.vacancy}}</li>
                                </ul>
                                <a :href="job.applyLink" target="_blank" class="button semi-button-info text-capitalize">{{ $t("Continue Apply")}}</a>
                            </div>
                        </div>
                    </div>
                </template>
            </section>
        </div>
    </dashboard-layout>
</template>
<script>
    import DashboardLayout from "../../layouts/DashboardLayout";
    import client from "../../app/api/Client";
    import CandidateJobLoader from "../../components/_loaders/CandidateJobLoader";

    export default {
        data() {
            return {
                isContentLoading: false,
                unfinishedJobs: []
            }
        },
        components: {
            DashboardLayout,
            CandidateJobLoader
        },
        methods: {
            async getUnfinishedJobs() {
                this.isContentLoading = true;
                try {
                    let {data: {data}} = await client().get('/candidate/unfinished-job');
                    this.unfinishedJobs = data;
                } catch (e) {
                }
                this.isContentLoading = false;

            },

            async deleteJobApply(applicationId) {
                let message = {
                    title: this.$t("Confirmation"),
                    body: this.$t("Are you sure, you want to discard this unfinished job?")
                };
                this.$dialog.confirm(message).then(() => {
                    client()
                        .delete(`candidate/${applicationId}/delete`)
                        .then(({data: {data, message}}) => {
                            this.$toast.success(this.$t(message));
                            this.unfinishedJobs = _.remove(this.unfinishedJobs, j => {
                                return j.application_id !== applicationId;
                            });
                        })
                        .catch(({response: {data, status}}) => {
                            this.$toast.error(data.message);
                        });
                })
            },
        },
        computed: {
            isEmpty() {
                return this.unfinishedJobs.length === 0;
            }
        },
        created() {
            this.getUnfinishedJobs();
        }
    }
</script>

<style scoped>
    .job-card ul {
        list-style: none;
    }
</style>